export default {
  emptyFieldValue: "--",
  sandboxPartnerIdPrefix: "SANDBOX-",
  sandboxPartnerNameSuffix: " (Sandbox)",
  colors: {
    primary100: "#E1EEF5",
    primary200: "#DBE3E6",
    primary300: "#7F9BA8",
    primary400: "#4E6773",
    primary500: "#263238",
    secondary200: "#DBF0EE",
    secondary300: "#C9E9E6",
    secondary400: "#80CBC4",
    secondary500: "#419F94",
    secondary600: "#327B73",
    neutral100: "#F7F9FC",
    neutral200: "#E9E7E9",
    neutral300: "#C8CBCC",
    neutral400: "#B1B6B9",
    neutral500: "#7C858A",
    warning200: "#FFE8D6",
    warning300: "#FFCAA2",
    warning400: "#FFAE70",
    warning500: "#FF913D",
    warning600: "#D65D00",
    error200: "#FFD7D6",
    error300: "#FFA4A2",
    error400: "#FF7370",
    error500: "#E02D29",
    semantic500: "#E02D29",
    semantic200: "#FFD7D6",
    yellow300: "#FFF5D6",
    yellow400: "#FFE9A2",
    yellow500: "#FFDE70"
  }
};
